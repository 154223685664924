// app/javascript/controllers/tabs_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "content"]
  static values = {
    defaultTab: { type: String, default: "friends" }
  }

  connect() {
    console.log("Tabs controller connected!")
    console.log("Tab targets:", this.tabTargets)
    console.log("Content targets:", this.contentTargets)

    // Check URL parameters for active tab
    const urlParams = new URLSearchParams(window.location.search)
    const activeTab = urlParams.get('tab') || this.defaultTabValue

    // Find the tab to activate
    const tabToActivate = this.tabTargets.find(tab =>
      tab.dataset.tab === activeTab
    ) || this.tabTargets[0]

    // Activate the appropriate tab
    if (tabToActivate) {
      this.switch({ currentTarget: tabToActivate })
    }
  }

  switch(event) {
    console.log("Switch called with tab:", event.currentTarget.dataset.tab)
    const selectedTab = event.currentTarget
    const tabName = selectedTab.dataset.tab

    // Update URL without page reload
    const url = new URL(window.location)
    url.searchParams.set('tab', tabName)
    window.history.pushState({}, '', url)

    // Update tab buttons
    this.tabTargets.forEach(tab => {
      tab.classList.toggle("active", tab === selectedTab)
    })

    // Show matching content
    this.contentTargets.forEach(content => {
      console.log("Content tab:", content.dataset.tabContent, "Matching:", content.dataset.tabContent === tabName)
      content.classList.toggle("active", content.dataset.tabContent === tabName)
    })
  }
}
