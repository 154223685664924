import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["email", "modal"]

  connect() {
    console.log("Modal controller connected")
    // Only hide the modal, not the entire container
    this.modalTarget.style.display = "none"
    this.boundClickOutside = this.clickOutside.bind(this)
    this.boundEscapeKey = this.escapeKey.bind(this)
  }

  disconnect() {
    // Clean up event listeners
    this.removeEventListeners()
  }

  open() {
    console.log("Opening modal")
    this.modalTarget.style.display = "block"
    this.emailTarget.focus()
    this.addEventListeners()
  }

  close() {
    console.log("Closing modal")
    this.modalTarget.style.display = "none"
    this.emailTarget.value = ""
    this.removeEventListeners()
  }

  addEventListeners() {
    document.addEventListener('click', this.boundClickOutside)
    document.addEventListener('keydown', this.boundEscapeKey)
  }

  removeEventListeners() {
    document.removeEventListener('click', this.boundClickOutside)
    document.removeEventListener('keydown', this.boundEscapeKey)
  }

  clickOutside(event) {
    if (!this.element.contains(event.target) || event.target === this.element) {
      this.close()
    }
  }

  escapeKey(event) {
    if (event.key === 'Escape') {
      this.close()
    }
  }

  submit(event) {
    event.preventDefault()

    fetch('/friend_requests', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({
        friend_request: {
          email: this.emailTarget.value
        }
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        // Let Turbo Stream handle the update
        this.close()
      } else {
        // Handle errors
        this.showError(data.error)
      }
    })
  }
}
