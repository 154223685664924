// app/javascript/controllers/geolocation_controller.js

// Import the function to render listing cards
import { renderListingCards } from './listing_cards';

// Function to fetch listings based on geolocation
async function fetchByGeolocation(latitude, longitude) {
  // Round coordinates for consistent cache keys
  latitude = parseFloat(latitude.toFixed(4));
  longitude = parseFloat(longitude.toFixed(4));

  // Define a cache key based on the rounded latitude and longitude
  const cacheKey = `geoListings_${latitude}_${longitude}`;

  // Check if the listings data is already cached
  let cachedResults = sessionStorage.getItem(cacheKey);
  if (cachedResults) {
    console.log("Using cached listings data");
    renderListingCards(JSON.parse(cachedResults));
    return;
  }

  console.log("Fetching new listings data");
  try {
    const response = await fetch(`/fetch_listings?latitude=${latitude}&longitude=${longitude}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
    const data = await response.json();

    // Cache the fetched listings data
    sessionStorage.setItem(cacheKey, JSON.stringify(data));
    // Cache the current geolocation
    sessionStorage.setItem('geoLocation', JSON.stringify({latitude, longitude}));

    renderListingCards(data);
  } catch (error) {
    console.error('Error:', error);
  }
}

// Function to handle geolocation reset with debouncing
let resetTimeout;
function handleGeolocationReset() {
  // Clear any existing timeout to prevent multiple rapid resets
  if (resetTimeout) clearTimeout(resetTimeout);

  // Set a new timeout for debouncing
  resetTimeout = setTimeout(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function(position) {
        const newLatitude = position.coords.latitude;
        const newLongitude = position.coords.longitude;

        const cachedGeoLocation = sessionStorage.getItem('geoLocation');
        if (cachedGeoLocation) {
          const { latitude: cachedLatitude, longitude: cachedLongitude } = JSON.parse(cachedGeoLocation);

          // Log the fetched and cached geolocations for comparison
          console.log(`Fetched geolocation: (${newLatitude}, ${newLongitude})`);
          console.log(`Cached geolocation: (${cachedLatitude}, ${cachedLongitude})`);

          // Debug log with detailed comparison data
          console.log(JSON.stringify({
            newCoords: {lat: newLatitude, lng: newLongitude},
            cachedCoords: JSON.parse(cachedGeoLocation),
            threshold: 0.01,
            diffLat: Math.abs(newLatitude - cachedLatitude),
            diffLng: Math.abs(newLongitude - cachedLongitude)
          }, null, 2));

          // Check if location has changed significantly (more than 0.01 degrees)
          const locationChanged =
            Math.abs(newLatitude - cachedLatitude) > 0.01 ||
            Math.abs(newLongitude - cachedLongitude) > 0.01;

          if (!locationChanged) {
            // If location hasn't changed significantly, try to use cached data
            const cacheKey = `geoListings_${cachedLatitude}_${cachedLongitude}`;
            const cachedListings = sessionStorage.getItem(cacheKey);

            if (cachedListings) {
              console.log("Using cached listings - location unchanged");
              renderListingCards(JSON.parse(cachedListings));
              return;
            } else {
              console.log("Cache miss - fetching new data");
            }
          } else {
            console.log("Location changed significantly - fetching new data");
          }
        }

        // Fetch new data if:
        // 1. No cached location exists
        // 2. Location changed significantly
        // 3. Cache miss for listings
        fetchByGeolocation(newLatitude, newLongitude);
      }, () => {
        // Handle geolocation permission denied error
        console.error("Geolocation permission denied");
      });
    }
  }, 500); // 500ms debounce delay
}

// Execute when the page loads
document.addEventListener("turbo:load", function() {
  // Retrieve cached geolocation coordinates from sessionStorage
  const cachedGeoLocation = sessionStorage.getItem('geoLocation');
  if (cachedGeoLocation) {
    // If cached coordinates exist, use them to fetch listings
    const { latitude, longitude } = JSON.parse(cachedGeoLocation);
    fetchByGeolocation(latitude, longitude);
  } else {
    // If no cached coordinates, use the browser's geolocation API to get current location
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function(position) {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;

        // Round latitude and longitude to 4 decimal places
        latitude = parseFloat(latitude.toFixed(4));
        longitude = parseFloat(longitude.toFixed(4));

        fetchByGeolocation(latitude, longitude);
      }, () => {
        // Handle cases where geolocation permission is denied
        console.error("Geolocation permission denied");
      });
    } else {
      // Handle cases where the browser doesn't support geolocation
      console.error("Geolocation not supported by this browser");
    }
  }

  // Listen for an event to reset geolocation
  document.addEventListener("reset-geolocation", function() {
    // Call the function to handle geolocation reset
    handleGeolocationReset();
  });
});
